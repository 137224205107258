<template>
	<div class="single-view tool-ssl-info">
		<div class="tool-box">
			<h1>{{ ii('REMOTE_SSL_INFO') }}</h1>
			<div>
				<div class="tool-input-box">
					<s-text-field
						v-model="app.query.domain"
						ref="tf"
						prepend-icon="globe"
						:placeholder="ii('ENTER_DOMAIN')"
						@change="refreshQuery"
						:loading="isLoading"
					></s-text-field>
				</div>

				<template v-if="info">
					<div v-if="isObjectEmpty(info)" class="no-data">
						{{ ii('NO_DATA') }}
					</div>
					<div v-else>
						<table class="result-tbl">
							<tr v-if="info.issuer">
								<td>{{ ii('ISSUER') }}:</td>
								<td>{{ info.issuer }}</td>
							</tr>
							<tr v-if="info.organization">
								<td>{{ ii('ORGANIZATION') }}:</td>
								<td>{{ info.organization }}</td>
							</tr>
							<tr v-if="info.country">
								<td>{{ ii('COUNTRY') }}:</td>
								<td>
									<s-flag :cc="info.country"></s-flag>
									{{ info.country }}
								</td>
							</tr>
							<tr v-if="validFrom">
								<td>{{ ii('VALID_FROM') }}:</td>
								<td>
									{{ validFrom }}
									<i class="info">{{ validFromFromNow }}</i>
								</td>
							</tr>
							<tr v-if="validTo">
								<td>{{ ii('VALID_TO') }}:</td>
								<td>
									{{ validTo }}
									<i class="info">{{ validToFromNow }}</i>
								</td>
							</tr>
							<tr v-if="info.signatureType">
								<td>{{ ii('SIGNATURE_TYPE') }}:</td>
								<td>{{ info.signatureType }}</td>
							</tr>
							<tr v-if="info.keySize">
								<td>{{ ii('KEY_SIZE') }}:</td>
								<td>{{ info.keySize }}</td>
							</tr>
							<tr>
								<td>{{ ii('STATUS') }}:</td>
								<td>
									<i v-if="info.status" class="fa fa-check"></i>
									<i v-else class="fa fa-remove"></i>
								</td>
							</tr>
							<tr v-if="info.san && info.san.length">
								<td>{{ ii('DOMAINS') }}:</td>
								<td>
									<div v-for="site in info.san">{{ site }}</div>
								</td>
							</tr>
						</table>
					</div>
				</template>

				<div class="page-text-content" v-html="markdownToHtml(app.texts.content)"></div>
			</div>
		</div>
	</div>
</template>

<style lang="less">
	.tool-ssl-info {
		.result-tbl {
			max-width: 500px;
			margin: 0 auto;

			& > tr > td:nth-child(1) {
				white-space: nowrap;
				vertical-align: baseline;
			}

			.info {
				color: grey;
				margin-left: 5px;
			}
		}

		.no-data {
			margin-top: 20px;
			text-align: center;
			font-weight: 500;
			color: #555;
		}
	}
</style>

<script>
	import moment from 'moment';

	export default {
		data() {
			return {
				info: null,
				isLoading: false,
			};
		},
		computed: {
			validFrom() {
				if (!this.info || !this.info.validFrom) return;

				return moment(this.info.validFrom * 1000).format('YYYY-MM-DD HH:mm:ss');
			},
			validFromFromNow() {
				if (!this.info || !this.info.validFrom) return;

				return moment(this.info.validFrom * 1000).fromNow();
			},
			validTo() {
				if (!this.info || !this.info.validTo) return;

				return moment(this.info.validTo * 1000).format('YYYY-MM-DD HH:mm:ss');
			},
			validToFromNow() {
				if (!this.info || !this.info.validTo) return;

				return moment(this.info.validTo * 1000).fromNow();
			},
		},
		methods: {
			async getInfo() {
				if (!this.app.query.domain) {
					this.info = null;
					return;
				}

				this.isLoading = true;
				let res = await this.post('SSL.getDomainInfo', this.app.query.domain);
				this.info = res || {};
				this.isLoading = false;
			}
		},
		watch: {
			$route() {
				this.getInfo();
			}
		},
		mounted() {
			if (this.app.query.domain) this.getInfo();

			this.$refs.tf.focus();
		}
	}
</script>
